<template>
  <!-- Main Wrapper -->

  <b-tabs>
    <b-tab v-if="pos_station && pos_station.id" active>
      <template v-slot:title>

        <span class="d-inline-block d-sm-none">
          <i class="fas fa-home"></i>
        </span>
        <span class="d-none d-sm-inline-block tab-text">{{
          this.$t("inventoryItems.inventory")
        }}</span>
      </template>
      <InventoryTab :pos_station="pos_station" />
    </b-tab>


  </b-tabs>
</template>
    
<script>

import InventoryTab from "./inventoryTab/inventorytabPage.vue"

export default {
  data() {
    return {
      pos_station: {}
    }
  },
  components: {
    InventoryTab,

  },
  methods: {
    getPosStation() {
      this.http.get("pos-stations/" + this.$route.params.pos_station_id, null, null, true).then((res) => {
        this.pos_station = res.data;
        console.log('pos station :', this.pos_station)
      });
    },
  },
  mounted() {
    this.getPosStation()
  },

};
</script>
    