export const state = {
  mapControl: false,
};

export const getters = {
  mapControl: (state) => state.mapControl,
};

export const mutations = {
  setMapControl(state) {
    console.log(state.mapControl);
    state.mapControl = !state.mapControl;
    console.log(state.mapControl);
  },
};

export const actions = {
  changeMapControl({ commit }) {
    commit("setMapControl");
  },
};
