


export default [
    {
        path: '/register',
        name:"register",
        component: () => import("../../../views/pages/auth/signup.vue"),
      },  
  
]