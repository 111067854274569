import { reactive } from "vue";
import { io } from "socket.io-client";

import store from "@/state/store";
var vuex = store._modules.root._children.auth.state;

export const state = reactive({
  connected: false,
  fooEvents: [],
  barEvents: [],
});

// "undefined" means the URL will be computed from the `window.location` object

export const socket = io(
  vuex.server,
  /* "http://localhost:5000", */ {
    autoConnect: false,
    query: {
      type: "pos_manager",
    },
  }
);

socket.on("connect", () => {
  state.connected = true;
});

socket.on("disconnect", () => {
  state.connected = false;
});

socket.on("foo", (...args) => {
  state.fooEvents.push(args);
});

socket.on("bar", (...args) => {
  state.barEvents.push(args);
});
