<template>
  <div class="page-header">
    <div class="page-title">
      <h4>{{ this.$t(title) }}</h4>
      <h6>{{ this.$t(title1) }}</h6>
    </div>
    <div class="page-btn" v-for="(item, index) in items" :key="index">
      <router-link :to="item.addname" class="btn btn-added">
        <vue-feather type="plus" />        
        {{  this.$t(item.text)   }}</router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    title1: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>
