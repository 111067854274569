import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import swal from "sweetalert2";

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

// Before each route evaluates...
router.beforeEach((routeTo, _, next) => {
  if (routeTo.path == "/login" && localStorage.user) {
    router.push("/home");
    return;
  } else if (routeTo.path == "/login" && !localStorage.user) {
    next();
  }
  const publicPages = [
    "/login",
    "/register",
    "/forgot-password",
    "reset-password",
    "/reset-password",
  ];
  const authPage = !publicPages.includes(routeTo.path);
  const loggedUser = localStorage?.user;

  console.log(
    "loggedUser :",
    loggedUser,
    "loggedUser  :",
    loggedUser ? true : false,
    "!loggedUser",
    !loggedUser
  );
  console.log("authPage", authPage);

  if (authPage && !loggedUser) console.log("auth condition run ");

  
  if (authPage && !loggedUser) {
    console.log(" step cond ");
    router.push("/login");
    // next();
  } else if (loggedUser) {
    console.log("ok 2");
    const decoded_user_data = JSON.parse(loggedUser);
    const user_roles = decoded_user_data?.roles.map(
      (item) => item.role.code
    );
    console.log("user type :", user_roles);
    const page_roles = routeTo.meta.auth_roles;
    const auth_type = routeTo.meta.auth_type;
    let auth_status = false;
    console.log("routeTo :", routeTo);
    for (let i = 0; i < user_roles?.length; i++) {
      console.log(" role :",user_roles[i] )
      console.log(59,page_roles.includes(user_roles[i]));
      if (page_roles.includes(user_roles[i]) && auth_type.includes("user")) {
        console.log("authed user");
        auth_status = true;
      } else {

        auth_status = false;
      }
    }

    if (auth_status) {
      console.log("auth complete");
    } else {
      router.push("/");
      setTimeout(() => {
        swal.fire({
          position: "top-end",
          icon: "error",
          title: "You are not allowed to go here",
          showConfirmButton: false,
          timer: 1500,
        });
      }, 1000);
    }
  }

  next();
});

export default router;
