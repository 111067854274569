import posInventory from "../../../views/pages/posStations/profile/Inventories/inventoryItems/inventoryItems.vue";

export default [
  {
    path: "pos-station/:pos_station_id/pos-inventory-items/:id",
    name: "posInventoryItems",
    component: posInventory,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "pos_hub_admin", "pos_hub_manager"],
      auth_type: ["user"],
    },
  },
];
