export default [
    {
        path: 'pos-raw-materials-categories',
        name: 'pos-raw-materials-categories',
        meta: {
            headerClass: "header",
            sidebarClass: "",

            auth_roles: ["pos_hub_admin"],
            auth_type: ["user"],



        },
        component: () => import("../../views/pages/PosRawMaterialsCategories/PosRawMaterialsCategoriesPage.vue"),
    },
    {
        path: 'pos-raw-materials-products/:id',
        name: 'raw_materials_products',
        meta: {
            headerClass: "header",
            sidebarClass: "",

            auth_roles: ["pos_hub_admin"],
            auth_type: ["user"],


        },
        component: () => import("../../views/pages/PosRawMaterialsProducts/PosRawMaterialsProductsPage.vue"),
    },

    {
        path: 'raw_materials_categories',
        name: 'raw_materials_categories',
        meta: {
            headerClass: "header",
            sidebarClass: "",
            auth_roles: ["pos_hub_admin"],
            auth_type: ["user"],

        },
        component: () => import("../../views/pages/PosRawMaterialsCategories/pos_raw_cateogries_list.vue"),
    },

]