import Index from "../../../views/pages/dashboard/index.vue";

export default [

  {
    path: "home",
    component: Index,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["admin", "pos_hub_admin", "pos_hub_manager"],
      auth_type: ["user"],
    },
  },
];
