
import login from "./routes/project_routes/login";
import signup from "./routes/project_routes/signup";
import home from "./routes/project_routes/home";
import pos_hub_admin from "./routes/project_routes/pos_hub_admin";
import rawMateriels from "./routes/rawMateriels";
import pos_stations from "./routes/project_routes/pos_stations";
import topic from "./routes/project_routes/topic";
import categories from "./routes/project_routes/categories";
import forget_password from "./routes/project_routes/forget_password";
import reset_password from "./routes/project_routes/reset_password";
import pos_inventory_items from "./routes/project_routes/pos_inventory_items";
export default [



  ...login,
  ...signup,
  ...forget_password,
  ...reset_password,

  {
    path: "/",
    redirect: "/dashboard/home"
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/components/layouts/layout.vue"),
    children: [

      ...home,
      ...pos_hub_admin,
      ...categories,
      ...topic,
      ...pos_stations,
      ...rawMateriels,
      ...pos_inventory_items
    ]

  },

];