 export default [
   {
     path: "pos-hub-admin/pos-stations",
     name: "posHubAdminPosStations",
     component: () =>
       import(
         "../../../views/pages/posStations/index.vue"
       ),
     meta: {
       headerClass: "header",
       sidebarClass: "",
       auth_roles: ["pos_hub_admin"],
       auth_type: ["user"],
       //auth_roles must be an array with single element, to change that go to src\router\index.js
     },
   },

   

   
  
   {
     path: "pos-hub-admin/pos-inventory-items/:id",
     name: "posHubAdminInventory",
     component: () =>
       import(
         "../../../views/pages/posStations/profile/InventoryItems/index.vue"
       ),
     meta: {
       headerClass: "header",
       sidebarClass: "",
       auth_roles: ["pos_hub_admin"],
       auth_type: ["user"],
     },
   },
 ];
  