<template>
  <div class="page-header">
    <div class="page-title">
      <h4> {{ mainPage.includes('.') ? $t(`${mainPage}`) : mainPage }} {{ mainPage ? " / " : "" }} {{ title.includes(".") ?
        $t(`${title}`) : title }}</h4>
      <h6>{{ title1.includes(".") ? $t(`${title1}`) : title1 }}</h6>
    </div>
    <div class="page-btn" v-for="(item, index) in items" :key="index">
      <a class="btn btn-added" type="button" data-bs-toggle="modal" :data-bs-target="item?.modalTarget">
        <img src="/assets/img/icons/plus.svg" class="me-2" alt="img" />{{ item.addBtnTitle?.includes(".") ?
          $t(`${item.addBtnTitle}`) : item.addBtnTitle }}</a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    mainPage: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    title1: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>
