export default [
  {
    path: "categories",
    component: () =>
      import("@/views/pages/categoriesManagement/index.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["pos_hub_admin"],
      auth_type: ["user"],
    },
  },
  {
    path: "category/:id",
    name: "ProductsByCategoryId",
    sensitive: true,
    component: () =>
      import("@/views/pages/categoriesManagement/product categories tab/products/index.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["pos_hub_admin"],
      auth_type: ["user"],
    },
  },
  {
    path: "category/:category_id/product_profile/:product_id",
    name: "productProfile",
    sensitive: true,
    component: () =>
      import(
        "@/views/pages/categoriesManagement/product categories tab/products/profile/product_profile.vue"
      ),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["pos_hub_admin"],
      auth_type: ["user"],
    },
  },
];
