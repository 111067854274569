<template>
    <!-- Main Wrapper -->

    <pageaddheader class="mt-3" :title="title" />

    <!-- /product list -->
    <div class="card ">
        <div class="card-body">
            <div class="table-top">
                <div class="search-set">

                    <div class="search-input">
                        <a class="btn btn-searchset"><img src="/assets/img/icons/search-white.svg" alt="img" /></a>
                        <div id="DataTables_Table_0_filter" class="dataTables_filter">
                            <label>
                                <input type="search" v-model="search_term" @input="search({ search: search_term, })"
                                    class="form-control form-control-sm" :placeholder="$t('popups.search_placeholder')"
                                    aria-controls="DataTables_Table_0" />
                            </label>
                        </div>
                    </div>
                </div>

            </div>

            <table style="table-layout: auto">
                <thead class="ant-table-thead">
                    <tr>


                        <th class="ant-table-cell text-start " colstart="0">


                            <!---->
                            <span class="ant-table-column-title ">{{
                                $t("tables.inventory_items.id")
                            }}</span>
                            <!---->
                        </th>
                        <th class="ant-table-cell text-start" colstart="1">


                            <!---->
                            <span class="ant-table-column-title ">{{
                                $t("tables.inventory_items.products")
                            }}</span>
                            <!---->
                        </th>



                        <th class="ant-table-cell text-start" colstart="3">


                            <!---->
                            <span class="ant-table-column-title ">{{
                                $t("tables.inventory_items.qty")
                            }}</span>
                            <!---->
                        </th>
                        <th v-if="pos_station && pos_station?.pos_type?.name != 'Restaurant'"
                            class="ant-table-cell text-start" colstart="3">


                            <!---->
                            <span class="ant-table-column-title ">{{
                                $t("tables.inventory_items.action")
                            }}</span>
                            <!---->
                        </th>



                    </tr>
                </thead>
                <tbody class="ant-table-tbody">
                    <!---->
                    <tr v-for="(inventory, index) in inventoryItems" :key="index"
                        class="ant-table-row ant-table-row-level-0">


                        <td class="ant-table-cell text-start">
                            <!---->{{ index + 1
                            }}<!---->
                        </td>
                        <template v-if="pos_station && pos_station?.pos_type?.name == 'Restaurant'">


                            <td class="ant-table-cell text-start">
                                <!---->{{ inventory?.pos_raw_materials_product_name
                                }}<!---->
                            </td>


                        </template>
                        <template v-else>


                            <td class="ant-table-cell text-start">
                                <!---->{{ inventory?.pos_product_name
                                }}<!---->
                            </td>


                        </template>


                        <td class="ant-table-cell text-start">
                            <!---->{{ inventory?.qty
                            }}<!---->
                        </td>




                    </tr>

                    <!---->
                </tbody>
                <!---->
            </table>


            <a-pagination class="d-flex justify-content-center mt-4" @change="onChange" v-model:current="page"
                v-model:pageSize="limit" :total="total" />
        </div>
    </div>

    <!--  Modal trigger button  -->
</template>
    
<script>
import { Modal } from "bootstrap"
export default {

    data() {
        return {
            page: 1,
            limit: 20,
            total: 0,
            // break unit data :
            unitBreakValue: 0,
            currentInventoryItem: {},
            // end
            filter: false,
            extractModalInstance: '',
            title: this.$t("inventoryItems.transport"),
            items: [
                {
                    text: "inventoryItems.transport",
                    addname: "",
                },

            ],
            mainPage: null,
            inventoryItems: [],
            data: {},
            search_term: "",

            product: {},
            current_inventory: {
                products: []
            },
            inventories: [],
            transferModal: ''
        }
    },
    props: {
        pos_station: Object
    },
    computed: {
        showPlus() {
            console.log("step 273 :", this.inventoryItems)
            return this.inventoryItems.length != this.current_inventory?.products.length
        },
    },
    methods: {








        getInventory() {
            this.http
                .post(`pos-inventories/paginate`, {
                    page: 1,
                    limit: 10000,
                }, null, true)
                .then((res) => {
                    this.inventories = res.data;
                });
        },
        getInventoryName() {
            this.http.get(`pos-inventories/${this.$route.params.id}`, { pos_station_id: this.$route.params.pos_station_id }, null, true).then((res) => {
                console.log("res :", res.data)
                this.title = res.data.name
            },null,true)
        },


        async getInventoryItems() {

            this.http
                .get(`pos-inventory-items/paginate-for-break-unit`, {
                    page: this.page,
                    limit: this.limit,
                    inventory_id: this.$route.params.id,
                    pos_station_id: this.$route.params.pos_station_id
                }, null, true)
                .then((res) => {
                    this.total = res.tot;
                    this.inventoryItems = res.data;
                });
        },
        search(options) {
            if (this.pos_station && this.pos_station.pos_type.name != 'Restaurant') {

                this.http
                    .get(`pos-inventory-items/break-unit-search`, {
                        search: options.search.trim(),
                        page: 1,
                        limit: 25,
                        lang: localStorage.current_language,
                        inventory_id: this.$route.params.inventoryId

                    })
                    .then((res) => {
                        this.inventoryItems = res.data;
                    });
            }
            else {
                this.http
                    .post(`pos-inventory-items/search`, {
                        search: options.search.trim(),
                        page: 1,
                        limit: 25,
                        lang: localStorage.current_language,
                        inventory_id: Number(this.$route.params.inventoryId)

                    })
                    .then((res) => {
                        this.inventoryItems = res.data;
                    });
            }
        },
        onChange(pageNumber, pageSize) {
            console.log("page :", pageNumber, "total :", pageSize);
            this.getInventoryItems(); /* add your function that render you table data */
        },

        /* break unit functions  */

        /* end */
    },
    mounted() {

        // const modal = new Modal("#transferInventoryModal")
        // this.transferModal = modal
        // this.pos_station = JSON.parse(localStorage.pos_station)
        console.log("pos station store :", this.pos_station)
        this.getInventoryItems();
        this.getInventory();
        this.getInventoryName()


    }
};

</script >