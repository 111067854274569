import store from "@/state/store";
import jwt_decode from "jwt-decode";
import { socket } from "@/services/socket";

export default {
  install(Vue) {
    var self = Vue.config.globalProperties;
    window.login = {
      loginUser(email, pass) {
        return new Promise((resolve) => {
          console.log("login clicked");
          self.http.post("users/pos-hub-login", { email, pass }).then((res) => {
            if (res.status) {
              console.log("logged user step 10 :", res);
              socket.connect();
              let user_data = jwt_decode(res.data.token).user_data;
              user_data.pass = self.$e.encrypt(pass);
              store.commit("addUserData", user_data);
              window.loginDone = true;
              resolve(true);
            } else {
              self.$swal.fire(self.$t("popups.error"), res.msg);
              resolve(false);
            }

            console.log("reject", res);
          });
        });
      },
    };
    Vue.config.globalProperties.login = window.login;
  },
};
