<template>
    <div class="row justify-content-between">

      
    
      <div class="col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das2">
          <div class="dash-counts">
            <h4>{{count.pos_hub_count}}</h4>
            <h5>{{$t("home.pos_hubs_count")}}</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="file-text"></vue-feather>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count das3">
          <div class="dash-counts">
            <h4>{{count.pos_count}}</h4>
            <h5>{{$t("home.pos_stations_count")}}</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="file"></vue-feather>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-12 d-flex">
        <div class="dash-count">
          <div class="dash-counts">
            <h4>{{count.products_count}}</h4>
            <h5>{{$t("home.total_products")}}</h5>
          </div>
          <div class="dash-imgs">
            <vue-feather type="user"></vue-feather>
          </div>
        </div>
      </div>
    </div>
    <!-- Button trigger modal -->
 
    <div class="card mb-0">
      <div class="card-body">
        <h4 class="card-title">Expired Products</h4>
        <div class="table-responsive dataview datatableIndex">
          <table style="table-layout: auto">
                <thead class="ant-table-thead">
                  <tr>
                 
  
                    <th class="ant-table-cell " colstart="1" colend="2">
                      <!----><!---->
                      <span class="ant-table-column-title">{{
                        $t("home.subscriptions.no")
                      }}</span>
                      <!---->
                    </th>
                    <th class="ant-table-cell " colstart="2" colend="3">
                      <!----><!---->
                      <span class="ant-table-column-title">{{
                        $t("home.subscriptions.name")
                      }}</span>
  
                      <!---->
                    </th>
                    <th class="ant-table-cell " colstart="3" colend="3">
                      <!----><!---->
                      <span class="ant-table-column-title">{{
                        $t("home.subscriptions.type")
                      }}</span>
  
                      <!---->
                    </th>
                    <th class="ant-table-cell " colstart="3" colend="3">
                      <!----><!---->
                      <span class="ant-table-column-title">{{
                        $t("home.subscriptions.sub_package")
                      }}</span>
  
                      <!---->
                    </th>
                    <th class="ant-table-cell " colstart="3" colend="3">
                      <!----><!---->
                      <span class="ant-table-column-title">{{
                        $t("home.subscriptions.period")
                      }}</span>
  
                      <!---->
                    </th>
                    <th class="ant-table-cell " colstart="3" colend="3">
                      <!----><!---->
                      <span class="ant-table-column-title">{{
                        $t("home.subscriptions.total_price")
                      }}</span>
  
                      <!---->
                    </th>
                  </tr>
                </thead>
                <tbody class="ant-table-tbody">
                  <!---->
                  <tr v-for="(latest_subscription, index) in latest_subscriptions" :key="index"  class="ant-table-row ant-table-row-level-0">
                    
  
                    <td class="ant-table-cell">
                     {{ index +1 }}
                    </td>
  
                    <td class="ant-table-cell">
                     {{ latest_subscription.name }}
                    </td>
                    
                    <td class="ant-table-cell">
                        
                      {{ $i18n.locale == "ar" ?  latest_subscription.type :latest_subscription.type_en }}
                      
                    </td>
                    <td class="ant-table-cell">
                        
                      {{ $i18n.locale == "ar" ?  latest_subscription.sub_package :latest_subscription.sub_package_en }}

                    </td>
                    <td class="ant-table-cell">
                        {{ latest_subscription.period }}
                      
                    </td>
                    <td class="ant-table-cell">
                      {{ $i18n.locale =="en"?  latest_subscription.total_price+" "+" SAR" : latest_subscription.total_price+" "+" ريال سعود"  }}

                      
                    </td>
                  </tr>
  
                  <!---->
                </tbody>
                <!---->
              </table>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { ref } from "vue";
  
  const currentDate = ref(new Date());
  
  
  export default {
    data() {
      return {

        
        subscriptionCount :[],
      user:{},
      count : {},
      latest_subscriptions:[],
  
        startdate: currentDate,
      };
    },
    methods: {
   
      chart(){
        this.http.get("charts/pos-hub-manager-chart",{pos_hub_manager_id: this.user.id },null,true).then((res)=>{
          console.log("res", res.data)
                this.count =res.data.count
                this.latest_subscriptions= res.data.latest_subscriptions

        })
      }
    },
    mounted() {
      this.user = JSON.parse(localStorage.user)
      if(this.user.roles.some(role => role.role.code =="pos_hub_manager")){

        this.chart()

      }
    },
  };
  </script>
  