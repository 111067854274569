export default [
  {
    path: "pos-stations",
    name: "pos-stations",
    sensitive: true,
    component: () => import("../../../views/pages/posStations/index.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["pos_hub_manager"],
      //auth_roles must be an array with single element, to change that go to src\router\index.js
      auth_type: ["user"],
    },
  },
  {
    path: "pos-station/:id",
    name: "posStationPage",
    sensitive: true,

    component: () =>
      import(
        "../../../views/pages/posStations/profile/index.vue"
      ),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["pos_hub_admin", "pos_hub_manager"],
      auth_type: ["user"],
    },
  },

  {
    path: "pos-station/:id/pos-categories-products/:pos_category_id",
    name: "posProductsByPosStationIdAndPosCategoryId",
    sensitive: true,

    component: () =>
      import(
        "../../../views/pages/posStations/profile/Categories/product categories tab/products/index.vue"
      ),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["pos_hub_admin", 'pos_hub_manager'],
      auth_type: ["user"],
    },
  },
  {
    path: "pos-station/:id/pos-categories-products/:pos_category_id/pos-product/:pos_product_id",
    name: "posProductProfileByPosStationIdAndPosCategoryId",
    sensitive: true,

    component: () =>
      import(
        "../../../views/pages/posStations/profile/Categories/product categories tab/products/profile/product_profile.vue"
      ),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["pos_hub_admin", 'pos_hub_manager'],
      auth_type: ["user"],
    },
  },
  // C:\work\POS work folder\pos_manager\src\views\pages\posStations\profile\Categories\product categories tab\index.vue

  {
    path: "pos-profile/:id",
    name: "posstationProfiles",
    component: () =>
      import("../../../views/pages/posStations/profile/index.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["pos_hub_manager"],
      auth_type: ["user"],
    },
  },
  {
    path: "pos-hubs",
    name: "posStationHubs",
    component: () => import("../../../views/pages/posStation Hubs/index.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["pos_hub_manager"],
      auth_type: ["user"],
    },
  },
  {
    path: "pos-hub/pos-station/:id",
    name: "posStationByPosHubId",
    sensitive: true,
    component: () => import("../../../views/pages/posStations/index.vue"),
    meta: {
      headerClass: "header",
      sidebarClass: "",
      auth_roles: ["pos_hub_manager"],
      auth_type: ["user"],
    },
  },
 

];
