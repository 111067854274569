<template>
  <div id="app">
    <router-view :key="$route.name" />
  </div>
</template>
<script>

import { socket } from "@/services/socket";
export default {
  name: "App",
  data: function () {
    return { status: false };
  },
  mounted() {
    console.log("locale", this.$i18n.locale)

    if (this.$i18n.locale == "ar") {
      localStorage.setItem("current_language", "ar")
    }
    else if (this.$i18n.locale == "en") {
      localStorage.setItem("current_language", "en")

    }

    // Sidebar Visible

    // if(localStorage.getItem('current_language')){
    // 	let html = document.querySelector("head")
    // 	html.innerHTML += `
    // 	<link rel="stylesheet" href="/style.css">
    // 	`
    // }else{
    // 	let html = document.querySelector("head")
    // 	html.innerHTML += `
    // 	<link rel="stylesheet" href="/style.css">
    // 	`}

    if (localStorage.getItem("current_language") == "ar") {
      let html = document.querySelector("#lang-changer");
      html.setAttribute("href", "/css/style_rtl.css");
      // setTimeout(() => {
      //   JsLoadingOverlay.hide();
      // }, 300);
    } else {
      let html = document.querySelector("#lang-changer");
      html.setAttribute("href", "/css/style.css");
      // setTimeout(() => {
      //   JsLoadingOverlay.hide();
      // }, 300);
    }


    if (localStorage.user) {

      socket.connect()
    }
    $(".open-layout").on("click", function (s) {
      s.preventDefault();
      $(".sidebar-layout").addClass("show-layout");
      $(".sidebar-settings").removeClass("show-settings");
    });
    $(".btn-closed").on("click", function (s) {
      s.preventDefault();
      $(".sidebar-layout").removeClass("show-layout");
    });
    $(".open-settings").on("click", function (s) {
      s.preventDefault();
      $(".sidebar-settings").addClass("show-settings");
      $(".sidebar-layout").removeClass("show-layout");
    });

    $(".btn-closed").on("click", function (s) {
      s.preventDefault();
      $(".sidebar-settings").removeClass("show-settings");
    });

    $(".open-siderbar").on("click", function (s) {
      s.preventDefault();
      $(".siderbar-view").addClass("show-sidebar");
    });

    $(".btn-closed").on("click", function (s) {
      s.preventDefault();
      $(".siderbar-view").removeClass("show-sidebar");
    });

    if ($(".toggle-switch").length > 0) {
      const toggleSwitch = document.querySelector(
        '.toggle-switch input[type="checkbox"]'
      );
      const currentTheme = localStorage.getItem("theme");
      var app = document.getElementsByTagName("BODY")[0];

      if (currentTheme) {
        app.setAttribute("data-theme", currentTheme);

        if (currentTheme === "dark") {
          toggleSwitch.checked = true;
        }
      }

      function switchTheme(e) {
        if (e.target.checked) {
          app.setAttribute("data-theme", "dark");
          localStorage.setItem("theme", "dark");
        } else {
          app.setAttribute("data-theme", "light");
          localStorage.setItem("theme", "light");
        }
      }

      toggleSwitch.addEventListener("change", switchTheme, false);
    }

    if (window.location.hash == "#LightMode") {
      localStorage.setItem("theme", "dark");
    } else {
      if (window.location.hash == "#DarkMode") {
        localStorage.setItem("theme", "light");
      }
    }

    $("ul.tabs li").click(function () {
      var $this = $(this);
      var $theTab = $(this).attr("id");
      console.log($theTab);
      if ($this.hasClass("active")) {
        // do nothing
      } else {
        $this
          .closest(".tabs_wrapper")
          .find("ul.tabs li, .tabs_container .tab_content")
          .removeClass("active");
        $(
          '.tabs_container .tab_content[data-tab="' +
          $theTab +
          '"], ul.tabs li[id="' +
          $theTab +
          '"]'
        ).addClass("active");
      }
    });

    $(".add-setting").on("click", function (e) {
      e.preventDefault();
      $(".preview-toggle.sidebar-settings").addClass("show-settings");
    });
    $(".sidebar-close").on("click", function (e) {
      e.preventDefault();
      $(".preview-toggle.sidebar-settings").removeClass("show-settings");
    });
    $(".navigation-add").on("click", function (e) {
      e.preventDefault();
      $(".nav-toggle.sidebar-settings").addClass("show-settings");
    });
    $(".sidebar-close").on("click", function (e) {
      e.preventDefault();
      $(".nav-toggle.sidebar-settings").removeClass("show-settings");
    });

    // DarkMode with LocalStorage
    if ($("#dark-mode-toggle").length > 0) {
      $("#dark-mode-toggle").children(".light-mode").addClass("active");
      let darkMode = localStorage.getItem("darkMode");

      const darkModeToggle = document.querySelector("#dark-mode-toggle");

      const enableDarkMode = () => {
        document.body.setAttribute("data-theme", "dark");
        $("#dark-mode-toggle").children(".dark-mode").addClass("active");
        $("#dark-mode-toggle").children(".light-mode").removeClass("active");
        localStorage.setItem("darkMode", "enabled");
      };

      const disableDarkMode = () => {
        document.body.removeAttribute("data-theme", "dark");
        $("#dark-mode-toggle").children(".dark-mode").removeClass("active");
        $("#dark-mode-toggle").children(".light-mode").addClass("active");
        localStorage.setItem("darkMode", null);
      };

      if (darkMode === "enabled") {
        enableDarkMode();
      }

      darkModeToggle.addEventListener("click", () => {
        darkMode = localStorage.getItem("darkMode");

        if (darkMode !== "enabled") {
          enableDarkMode();
        } else {
          disableDarkMode();
        }
      });
    }
  },
};
</script>



