import store from "@/state/store";
import axios from "axios";
var state = store._modules.root._children.auth.state;
import responseAlert from "./sweet_alert";
import "js-loading-overlay";
const loading_options = {
  spinnerIcon: "ball-beat",
  overlayBackgroundColor: "#ffffff",
  overlayOpacity: 0.6,
  spinnerColor: "#FF9F43",
  spinnerSize: "2x",
  overlayIDName: "overlay",
  spinnerIDName: "spinner",
};
export default {
  install(Vue) {
    var self = Vue.config.globalProperties;
    Vue.config.globalProperties.http = {
      post(url, data, options, hasAuth) {
        if (url.toLowerCase().includes("search")) {
          console.log("search request");
        } else {
          JsLoadingOverlay.show(loading_options);
        }
        return new Promise((resolve) => {
          if (!hasAuth || window.loginDone) callServer();
          else {
            var loginInterval = setInterval(() => {
              console.log(window.loginDone);
              if (window.loginDone) {
                clearInterval(loginInterval);
                callServer();
              }
            }, 100);
          }

          function callServer() {
            if (!options) options = {};
            if (!options.headers) options.headers = {};
            // if (state.token) {
            //   options.headers["x-auth"] = state.token;
            //// data.user_id = state.currentUser.id;
            // }
            //          data.user_id = state.currentUser.id;

            console.log(options);
            data.lang = Vue.config.globalProperties.$i18n.locale;
            axios
              .post(state.baseUrl + url, data, options)
              .then((resp) => {
                console.log(resp.headers["x-auth"]);
                if (resp.headers["x-auth"]) {
                  state.token = resp.headers["x-auth"];
                  console.log(data.pass);
                  resp.data.data.pass = self.$e.encrypt(data.pass);
                  state.currentUser = resp.data.data;
                  localStorage.currentUser = JSON.stringify(resp.data.data);
                }
                console.log({ ShityData: resp.data });
                if (resp.data.status) {
                  resolve(resp.data);
                  JsLoadingOverlay.hide();
                } else {
                  resolve(resp.data);
             
                  self.$swal.fire(self.$t("popups.error"), resp.data.msg);
                  JsLoadingOverlay.hide();
                }
              })
              .catch((e) => {
                console.log(e);
                self.$swal.fire(
                  self.$t("popups.error"),
                  self.$t("popups.network_error")
                );
                JsLoadingOverlay.hide();
              });
          }
        });
      },
      do(url, data, options) {
        JsLoadingOverlay.show(loading_options);

        return new Promise((resolve) => {
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          // if (state.token) {
          //   options.headers["x-auth"] = state.token;
          //// data.user_id = state.currentUser.id;
          // }
          //data.user_id = state.currentUser.id;

          console.log(options);
          data.lang = Vue.config.globalProperties.$i18n.locale;
          var formData = new FormData();
          for (var key in data) {
            formData.append(key, data[key]);
          }
          axios
            .post(state.baseUrl + url, formData, options)
            .then((resp) => {
              console.log(resp.headers["x-auth"]);
              if (resp.headers["x-auth"]) {
                state.token = resp.headers["x-auth"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(state.token);
              }
              if (resp.data.status) {
                resolve(resp.data);
                JsLoadingOverlay.hide();
              } else {
                Vue.config.globalProperties.popup.alert({
                  title: "popups.error",
                  msg: resp.data.msg,
                });
                JsLoadingOverlay.hide();
              }
            })
            .catch((e) => {
              console.log(e);
              self.$swal.fire(
                self.$t("popups.error"),
                self.$t("popups.network_error")
              );
              JsLoadingOverlay.hide();
            });
        });
      },
      put(url, id, data, options) {
        JsLoadingOverlay.show(loading_options);

        return new Promise((resolve) => {
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          // if (state.token) {
          //   options.headers["x-auth"] = state.token;
          //// data.user_id = state.currentUser.id;
          // }

          //// data.user_id = state.currentUser.id;

          console.log(options);
          // data.lang = Vue.config.globalProperties.$i18n.locale
          axios
            .put(state.baseUrl + url + "/" + id, data, options)
            .then((resp) => {
              console.log(resp.headers["x-auth"]);
              if (resp.headers["x-auth"]) {
                state.token = resp.headers["x-auth"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(state.token);
              }
              if (resp.data.status) {
                resolve(resp.data);
                JsLoadingOverlay.hide();
              } else {
                Vue.config.globalProperties.popup.alert({
                  title: "popups.error",
                  msg: resp.data.message,
                });
                JsLoadingOverlay.hide();
              }
            })
            .catch((e) => {
              console.log(e);
              self.$swal.fire(
                self.$t("popups.error"),
                self.$t("popups.network_error")
              );
              JsLoadingOverlay.hide();
            });
        });
      },
      get(url, data, options, hasAuth) {
        if (url.toLowerCase().includes("search")) {
          console.log("search request");
        } else {
          JsLoadingOverlay.show(loading_options);
        }
        return new Promise((resolve) => {
          if (!hasAuth || window.loginDone) callServer();
          else {
            console.log("here 100");

            var loginInterval = setInterval(() => {
              console.log("here 200", window.loginDone);
              if (window.loginDone) {
                clearInterval(loginInterval);
                callServer();
              }
            }, 100);
          }
          function callServer() {
            if (!data) data = {};
            if (!options) options = {};
            if (!options.headers) options.headers = {};
            if (state.token) {
              options.headers["x-auth"] = state.token;
              //data.user_id = state.currentUser.id;
            }
            console.log("step 200 : ", store.state.currentUser);

            console.log(options);
            data.lang = Vue.config.globalProperties.$i18n.locale;
            axios
              .get(
                state.baseUrl +
                url +
                "?" +
                new URLSearchParams(data).toString(),
                data,
                options
              )
              .then((resp) => {
                // console.log("the status is",resp.data.status)
                console.log(resp.headers["x-auth"]);
                if (resp.headers["x-auth"]) {
                  state.token = resp.headers["x-auth"];
                  console.log(data.pass);
                  resp.data.data.pass = self.$e.encrypt(data.pass);
                  state.currentUser = resp.data.data;
                  localStorage.currentUser = JSON.stringify(resp.data.data);
                  console.log(state.token);
                }
                if (resp.data.status) {
                  resolve(resp.data);
                  JsLoadingOverlay.hide();
                } else {
                  resolve(resp.data);
                  self.$swal.fire(self.$t("popups.error"), resp.msg);
                  JsLoadingOverlay.hide();
                }
              })
              .catch((e) => {
                console.log(e);
                self.$swal.fire(
                  self.$t("popups.error"),
                  self.$t("popups.network_error")
                );
                JsLoadingOverlay.hide();
              });
          }
        });
      },
      delete(url, id, options) {
        JsLoadingOverlay.show(loading_options);

        return new Promise((resolve) => {
          var data = {};
          if (!options) options = {};
          if (!options.headers) options.headers = {};
          // if (state.token) {
          //   options.headers["x-auth"] = state.token;
          //// data.user_id = state.currentUser.id;
          // }
          //data.user_id = state.currentUser.id;

          console.log(options);
          data.lang = Vue.config.globalProperties.$i18n.locale;
          axios
            .delete(
              state.baseUrl +
              url +
              "/" +
              id +
              "?" +
              new URLSearchParams(data).toString(),
              options
            )
            .then((resp) => {
              console.log(resp.headers["x-auth"]);
              if (resp.headers["x-auth"]) {
                state.token = resp.headers["x-auth"];
                console.log(data.pass);
                resp.data.data.pass = self.$e.encrypt(data.pass);
                state.currentUser = resp.data.data;
                localStorage.currentUser = JSON.stringify(resp.data.data);
                console.log(state.token);
              }
              if (resp.data.status) {
                resolve(resp.data);
                JsLoadingOverlay.hide();
              } else {
                Vue.config.globalProperties.popup.alert({
                  title: self.$t("popups.error"),
                  msg: resp.data.msg,
                });
                JsLoadingOverlay.hide();
              }
            })
            .catch((e) => {
              console.log(e);
              self.$swal.fire(
                self.$t("popups.error"),
                self.$t("popups.network_error")
              );
              JsLoadingOverlay.hide();
            });
        });
      },
    };
  },
};
